export const articleFormatDate = (date: string, lang: string): string => {
  const newDate = new Date(date);
  const month = newDate.toLocaleString(lang, { month: 'long' });
  const day = newDate.getDate();
  const year = newDate.getFullYear();

  const pluralRules = new Intl.PluralRules(lang, { type: 'ordinal' });
  const daySuffix = (item) => {
    const suffixes = {
      one: 'st',
      two: 'nd',
      few: 'rd',
      other: 'th',
    };
    const pluralCategory = pluralRules.select(item);

    return suffixes[pluralCategory] || suffixes.other;
  };

  const dateString = `${day}${daySuffix(day)} ${month} ${year}`;

  return dateString;
};

export default {
  articleFormatDate,
};
